import axios, { AxiosResponse } from 'axios';

import { ChatDTO } from '../dto/response/chat.dto';
import { SuccessResponseDTO } from '../dto/response/success.dto';
const GET_CHAT_CHANNEL_BY_ORDER = 'admin/chat/channel';
export const getChatChannelByOrderId = (id: string): Promise<AxiosResponse<SuccessResponseDTO<ChatDTO>>> => {
  return axios.get(`${GET_CHAT_CHANNEL_BY_ORDER}/${id}`);
};

export const getSendbirdSessionToken = async (userId: string, expires_at?: string) => {
  return await axios.get(`connect/sendbird/${userId}/session-token${expires_at ? `?expires_at=${expires_at}` : ''}`);
};
