import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useRecoilState } from 'recoil';

import {
  GetProductPricesData,
  optionGroup,
  PatchAdditionalPriceRequest,
  ResponseDtoAdditionalPrice,
} from '../../client';
import { ErrorResponseDTO } from '../dto/response/error.dto';
import { PaginatedResponseDTO } from '../dto/response/paginated.dto';
import { ProductResponseDTO } from '../dto/response/product.dto';
import { ProductRemakePolicyDTO } from '../dto/response/productAdditionalOption.dto';
import { SuccessResponseDTO } from '../dto/response/success.dto';
import { SupplyDTO, SupplyListByLabDTO } from '../dto/response/supply.dto';
import { ProductStateDTO, SingleProductDto } from '../dto/states/productsState.dto';
import * as productApi from './product.api';
import { getAdditionalOptionPrices } from './product.api';
import { productState } from './productState';

export function useProductActions() {
  const [productData, setProductData] = useRecoilState<ProductStateDTO>(productState);

  const { errorMessage, isLoading, isError, allProductSupplyList, supplyListByLab } = productData;

  const queryClient = useQueryClient();
  // GET
  const useAllProductList = ({ status, page, size, sort }: GetProductPricesData) =>
    useQuery({ queryKey: ['get-products', status, page, size, sort], queryFn: productApi.getProductList });

  const useProductOptions = (option: optionGroup) =>
    useQuery({ queryKey: ['get-product-option', option], queryFn: productApi.getProductOptions });

  const useAllProductOptions = () => {
    const { data: prosthesisOption, isLoading: prosthesisLoading } = useProductOptions('PROSTHESIS');
    const { data: methodOption, isLoading: methodLoading } = useProductOptions('METHOD');
    const { data: materialOption, isLoading: materialLoading } = useProductOptions('MATERIAL');
    const { data: shapeOption, isLoading: shapeLoading } = useProductOptions('SHAPE');
    // const { data: priceUnitOption, isLoading: priceUnitLoading } = useProductOptions('PRICE_UNIT');
    // const { data: colorCodeOption, isLoading: colorCodeLoading } = useProductOptions('COLOR_CODE');
    const queryLoading = prosthesisLoading || methodLoading || materialLoading || shapeLoading;
    // || priceUnitLoading || colorCodeLoading;
    return {
      queryLoading,
      prosthesisOption,
      methodOption,
      materialOption,
      shapeOption,
      // priceUnitOption,
      // colorCodeOption,
    };
  };

  // POST
  const useProductItemMutation = () => {
    return useMutation({
      mutationFn: productApi.postProductItem,
      onSettled: async () => await queryClient.invalidateQueries({ queryKey: ['get-products'] }),
    });
  };

  const useProductOptionMutation = () => {
    return useMutation({
      mutationFn: productApi.postProductOptions,
      onSettled: async () => await queryClient.invalidateQueries({ queryKey: ['get-product-option'] }),
    });
  };

  // PATCH
  const useProductItemLifeCycleMutation = () => {
    return useMutation({
      mutationFn: productApi.patchProductItemLifeCycle,
      onSettled: async () => await queryClient.invalidateQueries({ queryKey: ['get-products'] }),
    });
  };

  // delete product
  const useProductItemDelete = () => {
    return useMutation({
      mutationFn: productApi.deleteProductItem,
      onSettled: async () => await queryClient.invalidateQueries({ queryKey: ['get-products'] }),
    });
  };

  const getProductMethods = useMutation({
    mutationFn: productApi.getProductMethods,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        allProductMethods: response.data.data.results,
      }));
    },
  });

  const getProductMaterials = useMutation({
    mutationFn: productApi.getProductMaterials,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        allProductMaterials: response.data.data.results,
      }));
    },
  });

  const getProductShapes = useMutation({
    mutationFn: productApi.getProductShapes,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        allProductShapes: response.data.data.results,
      }));
    },
  });

  const deleteAllProductTypesById = useMutation({
    mutationFn: productApi.deleteAllProductTypesById,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
      }));
    },
  });

  const deleteProductMaterialsById = useMutation({
    mutationFn: productApi.deleteProductMaterialsById,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
      }));
    },
  });

  const deleteProductMethodsById = useMutation({
    mutationFn: productApi.deleteProductMethodsById,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
      }));
    },
  });

  const deleteProductShapesById = useMutation({
    mutationFn: productApi.deleteProductShapesById,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
      }));
    },
  });

  const postAllProductTypes = useMutation({
    mutationFn: productApi.postAllProductTypes,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
      }));
    },
  });

  const postProductMaterials = useMutation({
    mutationFn: productApi.postProductMaterials,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
      }));
    },
  });

  const postProductMethods = useMutation({
    mutationFn: productApi.postProductMethods,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
      }));
    },
  });

  const postProductShapes = useMutation({
    mutationFn: productApi.postProductShapes,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
      }));
    },
  });

  const createProduct = useMutation({
    mutationFn: productApi.createProduct,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
      }));
    },
  });

  const getAllProductsForListing = useMutation({
    mutationFn: productApi.getAllProductsForListing,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<ProductResponseDTO>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        allproductListing: response.data.data.results,
        isError: true,
      }));
    },
  });

  const productDeleteById = useMutation({
    mutationFn: productApi.productDeleteById,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<ProductResponseDTO>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
      }));
    },
  });

  const getProductById = useMutation({
    mutationFn: productApi.getProductById,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<ProductResponseDTO>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        productForEdit: response.data.data,
        isError: true,
      }));
    },
  });

  const updateProductById = useMutation({
    mutationFn: productApi.updateProductById,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<ProductResponseDTO>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
      }));
    },
  });

  const updateProductRemake = useMutation({
    mutationFn: productApi.updateProductRemake,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<any>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        allproductListing: response.data.data.results,
      }));
    },
  });

  const getAdminSupplyList = useMutation({
    mutationFn: productApi.getAdminSupplyList,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SupplyDTO>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        allProductSupplyList: response.data.data.results,
      }));
    },
  });

  const updateAdminSupplyList = useMutation({
    mutationFn: productApi.updateAdminSupplyList,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SupplyDTO>>>) => {
      console.log(response.data.data.results, 'results');
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        allProductSupplyList: response.data.data.results,
      }));
    },
  });

  const updateProductRemakePolicy = useMutation({
    mutationFn: productApi.updateProductRemakePolicy,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<ProductRemakePolicyDTO>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
      }));
    },
  });

  const getAdminSupplyListByLab = useMutation({
    mutationFn: productApi.getAdminSupplyListByLab,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SupplyListByLabDTO>>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        supplyListByLab: response.data.data.results,
      }));
    },
  });

  const getProductRemakePolicy = useMutation({
    mutationFn: productApi.getProductRemakePolicy,
    onMutate: () => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<ProductRemakePolicyDTO>>) => {
      setProductData((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        productRemakePolicy: response.data.data,
      }));
    },
  });

  return {
    isLoading,
    isError,
    errorMessage,
    productForEdit: productData.productForEdit,
    allProductSupplyList,
    supplyListByLab,
    useAllProductList,
    useProductOptions,
    useAllProductOptions,
    useProductItemMutation,
    useProductItemLifeCycleMutation,
    useProductItemDelete,
    useProductOptionMutation,
    getProductMethods: getProductMethods.mutateAsync,
    getProductMaterials: getProductMaterials.mutateAsync,
    getProductShapes: getProductShapes.mutateAsync,
    deleteAllProductTypesById: deleteAllProductTypesById.mutateAsync,
    deleteProductMaterialsById: deleteProductMaterialsById.mutateAsync,
    deleteProductMethodsById: deleteProductMethodsById.mutateAsync,
    deleteProductShapesById: deleteProductShapesById.mutateAsync,
    postProductMaterials: postProductMaterials.mutateAsync,
    postAllProductTypes: postAllProductTypes.mutateAsync,
    postProductMethods: postProductMethods.mutateAsync,
    postProductShapes: postProductShapes.mutateAsync,
    createProduct: createProduct.mutateAsync,
    getAllProductsForListing: getAllProductsForListing.mutateAsync,
    productDeleteById: productDeleteById.mutateAsync,
    getProductById: getProductById.mutateAsync,
    updateProductById: updateProductById.mutateAsync,
    updateProductRemake: updateProductRemake.mutateAsync,
    getAdminSupplyList: getAdminSupplyList.mutateAsync,
    updateAdminSupplyList: updateAdminSupplyList.mutateAsync,
    getAdminSupplyListByLab: getAdminSupplyListByLab.mutateAsync,
    updateProductRemakePolicy: updateProductRemakePolicy.mutateAsync,
    getProductRemakePolicy: getProductRemakePolicy.mutateAsync,
  };
}

//additional options
export const useAdditionalOptionPricesQuery = (): UseQueryResult<ResponseDtoAdditionalPrice> => {
  return useQuery({
    queryKey: ['additionalOptionPrices'],
    queryFn: getAdditionalOptionPrices,
  });
};

export const usePatchAdditionalOptionPrices = () => {
  return useMutation({
    mutationFn: async (data: PatchAdditionalPriceRequest) =>
      axios.patch(`connect/back-office/admin/additional-prices`, { ...data }).then((res) => res.data),
  });
};
