import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';

import { getSendbirdSessionToken } from '../../../../recoil/chat/chat.api';

interface ChatBoxProps {
  chattingChannelUrl: string | undefined;
  children: React.ReactNode;
}

function BoCustomSendbirdProvider({ chattingChannelUrl, children }: ChatBoxProps) {
  const [sessionToken, setSessionToken] = React.useState<string>('');

  useEffect(() => {
    if (!chattingChannelUrl) return;
    getSendbirdSessionToken('QC').then((res) => {
      setSessionToken(res.data?.data?.token as string);
    });

    return () => {
      setSessionToken('');
    };
  }, [chattingChannelUrl, getSendbirdSessionToken]);
  if (!sessionToken) {
    return <CircularProgress color={'inherit'} size={20} sx={{ ml: 1 }} />;
  }
  return (
    <SendbirdProvider
      appId={process.env.REACT_APP_SENDBIRD_APP_ID as string}
      userId={'QC'}
      accessToken={sessionToken}
      nickname={'admin'}
    >
      {children}
    </SendbirdProvider>
  );
}

export default BoCustomSendbirdProvider;
