import * as React from 'react';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, FormControl, InputLabel, Stack, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box } from '@mui/system';

import { PersonName } from '../../../client';
import CustomDialog from '../../../components/dialog/CustomDialog';
import CustomPopover from '../../../components/popover/CustomPopover';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';

const ROLE_LIST = [
  { id: 'manager', label: 'Manager', value: 'MANAGER' },
  { id: 'member', label: 'Member', value: 'MEMBER' },
];

interface IMemberListTableActionsProp {
  email: string;
  name: PersonName;
  role: string;
}

export default function MemberListTableActions({ email, name, role }: Readonly<IMemberListTableActionsProp>) {
  const [forceClose, setForceClose] = React.useState(true);

  const {
    open: changeRoleOpen,
    handleOpenDialogue: onOpenChangeRoleDialogue,
    handleCloseDialogue: onCloseChangeRoleDialogue,
  } = useDialogue();
  const {
    open: accountRemovalOpen,
    handleOpenDialogue: onOpenAccountRemovalDialogue,
    handleCloseDialogue: onCloseAccountRemovalDialogue,
  } = useDialogue();

  const [selectedRole, setSelectedRole] = useState<string>(role);
  const handleRoleChange = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    setSelectedRole(value);
  };

  return (
    <>
      <CustomPopover icon={<MoreVertIcon />} forceClose={forceClose} setForceClose={setForceClose}>
        <Stack>
          <Button variant="text" onClick={onOpenChangeRoleDialogue}>
            Change role
          </Button>
          <Button variant="text" onClick={onOpenAccountRemovalDialogue}>
            Remove account
          </Button>
        </Stack>
      </CustomPopover>

      {/* Change User Role */}
      <CustomDialog
        open={changeRoleOpen}
        title={'Change Role'}
        handleClose={onCloseChangeRoleDialogue}
        btnActionText={'Confirm'}
        btnAction={() => {
          onCloseChangeRoleDialogue();
          // MutateAsync
        }}
      >
        <Stack gap="20px" sx={{ padding: '20px 0' }}>
          <Box>
            <Typography variant="subtitle2">Email</Typography>
            <Typography variant="body2">{email}</Typography>
          </Box>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedRole}
                label="Role"
                onChange={handleRoleChange}
              >
                {ROLE_LIST.map((role) => (
                  <MenuItem key={role.id} value={role.value}>
                    {role.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </CustomDialog>

      {/* Account Removal */}
      <CustomDialog
        open={accountRemovalOpen}
        title={'Confirm account removal?'}
        handleClose={onCloseAccountRemovalDialogue}
        btnActionText={'Confirm'}
        btnAction={() => {
          onCloseAccountRemovalDialogue();
          // MutateAsync
        }}
      >
        <Stack gap="20px" sx={{ padding: '20px 0' }}>
          <Box>
            <Typography variant="subtitle2">Username</Typography>
            <Typography variant="body2">{`${name.firstName} ${name.lastName}`}</Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">Email</Typography>
            <Typography variant="body2">{email}</Typography>
          </Box>
          <Box>
            <Typography variant="body1">This account will be removed from the organization.</Typography>
          </Box>
        </Stack>
      </CustomDialog>
    </>
  );
}
