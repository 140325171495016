import * as React from 'react';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface IPropsDialog {
  title?: any;
  desc: any;
  dialogActions?: boolean;
  customActionBtn?: boolean;
  btnActionText: any;
  btnActionId?: any;
  btnAction?: any;
  alertType?: any;
  open: boolean;
  handleClose: any;
  salesActionBtn?: any;
  sx?: any;
  isloading?: boolean;
}

export default function CustomAlertDialog({
  title,
  desc,
  dialogActions = true,
  btnActionText,
  btnAction,
  btnActionId,
  customActionBtn,
  alertType = 'primary',
  open,
  handleClose,
  salesActionBtn,
  isloading,
  sx,
}: Readonly<IPropsDialog>) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && (
        <DialogTitle id="alert-dialog-title" sx={{ pt: 3, fontSize: 24, fontWeight: 700 }}>
          {title}
        </DialogTitle>
      )}
      <DialogContent sx={{ minWidth: 400, borderRadius: '.5rem' }}>
        <DialogContentText id="alert-dialog-description" sx={{ py: 2, fontSize: 18, color: '#333' }}>
          {desc}
        </DialogContentText>
      </DialogContent>
      {dialogActions && (
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          {btnActionText && !salesActionBtn ? (
            <Button
              autoFocus
              variant="contained"
              id={btnActionId}
              onClick={btnAction}
              disabled={isloading}
              sx={
                alertType === 'danger'
                  ? { background: '#CC4646 !important' }
                  : alertType === 'success'
                    ? { background: '#00AB55 !important' }
                    : { background: '#0083CA !important' }
              }
            >
              {btnActionText}
              {isloading ? <CircularProgress color={'inherit'} size={20} sx={{ ml: 1 }} /> : null}
            </Button>
          ) : (
            <Button
              autoFocus
              variant="contained"
              id={btnActionId}
              onClick={btnAction}
              sx={{ backgroundColor: '#00AB55 !important', pb: 0, pt: 0 }}
            >
              {btnActionText}
            </Button>
          )}
        </DialogActions>
      )}
      {customActionBtn && (
        <DialogActions sx={{ pt: '0 !important', pb: '2rem !important', justifyContent: 'center' }}>
          <Button autoFocus onClick={btnAction} sx={sx}>
            {btnActionText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
