import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';

import '../css/dialog.min.css';

interface IPropsDialog {
  iconClose?: boolean;
  open: boolean;
  title: any;
  titleSize?: any;
  customStyle?: any;
  handleClose: any;
  children?: React.ReactNode;
  isDisabled?: boolean;
  hideCloseViaClickOnbackDrop?: boolean;
  btnActionText?: string;
  btnAction?: () => void;
  btnDisabled?: boolean;
  btnType?: 'submit';
  formId?: string;
}

export default function CustomDialog({
  title,
  iconClose = false,
  titleSize = 24,
  customStyle,
  children,
  open,
  handleClose,
  hideCloseViaClickOnbackDrop = false,
  btnActionText,
  btnAction,
  btnDisabled,
  btnType,
  formId,
}: Readonly<IPropsDialog>) {
  return (
    <Dialog
      open={open}
      onClose={!hideCloseViaClickOnbackDrop ? handleClose : () => {}}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          customStyle,
        },
      }}
    >
      <DialogTitle sx={{ px: '1.75rem', pt: '1.5rem', pb: '1rem' }}>
        <Typography fontSize={titleSize} fontWeight={700}>
          {title}
        </Typography>
      </DialogTitle>
      {iconClose && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 15,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <Button onClick={btnAction} color="inherit" type={btnType} form={formId} disabled={btnDisabled ?? false}>
          {btnActionText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
