import * as React from 'react';
import { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import CustomAlertDialog from '../../../components/dialog/CustomAlertDialog';
import Iconify from '../../../components/iconify/Iconify';
import MenuPopover from '../../../components/popover/MenuPopover';
import { useProductActions } from '../../../recoil/products/useProduct';

interface Iprops {
  setOpenAddEditDlg: React.Dispatch<React.SetStateAction<boolean>>;
  row: any;
  getProductForDeleteHandler: (itemId: string) => void;
  setProductList: any;
  productList: any;
}
export default function TableRowActions({
  setOpenAddEditDlg,
  row,
  getProductForDeleteHandler,
  productList,
  setProductList,
}: Iprops) {
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
  const { productDeleteById, getAllProductsForListing } = useProductActions();
  const [openMenu, setOpenMenu] = useState<HTMLElement | null>(null);

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  const deleteSelectedProduct = (row: any) => {
    if (row?._id) {
      let dublicateArray = [...productList];
      let findProduct = productList.find((item: any) => item?._id === row?._id);
      if (row?.isNew && findProduct) {
        let findIndex = productList.findIndex((item: any) => item?._id === row?._id);
        dublicateArray.splice(findIndex, 1);
      } else if (findProduct && !row?.isNew) {
        let findIndex = productList.findIndex((item: any) => item?._id === row?._id);
        dublicateArray[findIndex] = {
          ...findProduct,
          isDeleted: !findProduct?.isDeleted,
        };
      }
      setProductList([...dublicateArray]);
      setOpenAlertDialog(false);
      // productDeleteById(id).then((res) => {
      //   getAllProductsForListing();
      // });
    }
  };
  return (
    <>
      <IconButton
        size="small"
        onClick={(e) => {
          setOpenMenu(e.currentTarget);
        }}
      >
        <Iconify icon={'eva:more-vertical-fill'} sx={{ color: 'action.active', width: 20, height: 20 }} />
      </IconButton>
      <MenuPopover id="popover-product-edit" open={openMenu} onClose={handleCloseMenu} sx={{ minWidth: 200 }}>
        <MenuItem onClick={() => getProductForDeleteHandler(row.id)}>
          {/*<Typography variant="body2">Edit</Typography>*/}
          <Typography variant="body2">Delete</Typography>
        </MenuItem>
      </MenuPopover>

      {openAlertDialog && (
        <CustomAlertDialog
          title={`${row?.isDeleted ? 'Restore ' : 'Delete'} Product?`}
          desc={`Are you sure to ${row?.isDeleted ? 'restore ' : 'delete'} this product?`}
          alertType={`${row?.isDeleted ? 'success' : 'danger'}`}
          btnActionText={row?.isDeleted ? 'Restore ' : 'Delete'}
          btnAction={() => {
            deleteSelectedProduct(row);
          }}
          open={openAlertDialog}
          handleClose={() => {
            setOpenAlertDialog(false);
          }}
        />
      )}
    </>
  );
}
