import { useEffect } from 'react';
import { FieldPath, useForm } from 'react-hook-form';
import { useBlocker } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';

import { PatchAdditionalPriceRequest } from '../../../client';
import { LeaveSiteDialogue } from '../../../components/dialog/LeaveSiteDialogue';
import FormProvider from '../../../components/RHF/FormProvider';
import RHFTextField from '../../../components/RHF/RHFTextField';
import { useAdditionalOptionPricesQuery, usePatchAdditionalOptionPrices } from '../../../recoil/products/useProduct';
import { handleNumberFieldInputValidate } from '../../../utils/inputHandler';

const AdditionalOptions = () => {
  const { data: additionalOptionsData } = useAdditionalOptionPricesQuery();
  const optionPrices = additionalOptionsData?.data;

  const defaultValues = {
    printing3DModel: optionPrices?.printing3DModel ?? 0,
    premiumFinishing: optionPrices?.premiumFinishing ?? 0,
    hook: optionPrices?.hook ?? 0,
    shipping: 0,
  };
  console.log(defaultValues);

  const methods = useForm<PatchAdditionalPriceRequest>({
    mode: 'onSubmit',
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    getValues,
    formState: { isValid, isDirty, dirtyFields },
  } = methods;

  const { mutateAsync, isPending } = usePatchAdditionalOptionPrices();

  let blocker: any = useBlocker(
    ({ currentLocation, nextLocation }) => false && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (optionPrices) {
      console.log('Resetting with optionPrices:', optionPrices);
      reset(defaultValues);
    }
  }, [optionPrices, reset]);

  const onSubmit = async (formData: PatchAdditionalPriceRequest) => {
    const updatedData: Partial<PatchAdditionalPriceRequest> = {};
    Object.keys(dirtyFields).forEach((key) => {
      updatedData[key as keyof PatchAdditionalPriceRequest] = watch(key as keyof PatchAdditionalPriceRequest);
    });

    await mutateAsync(updatedData, {
      onSuccess: () => {
        reset(formData);
      },
    });
  };

  // 확인중
  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const name = e.target.name as FieldPath<PatchAdditionalPriceRequest>;
    const newValue = Number(watch(name));
    const originalValue = defaultValues[name] ?? 0;
    console.log(newValue, originalValue, newValue !== originalValue);
    setValue(name, newValue, { shouldDirty: newValue !== originalValue });
    trigger(name);
  };

  const onChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const name = e.target.name as FieldPath<PatchAdditionalPriceRequest>;
    const newValue = Number(e.target.value);
    const originalValue = defaultValues[name] ?? 0;
    console.log(newValue, originalValue, newValue !== originalValue);
    setValue(name, newValue, { shouldDirty: newValue !== originalValue });
    trigger(name);
  };
  console.log(isDirty, dirtyFields);
  return (
    <FormProvider id={'additionalOptionPrice'} methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="40px" sx={{ px: 7, py: 5, width: '630px' }}>
        <Typography variant="h3">Additional option</Typography>
        <Stack gap="52px">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Printing 3D model</Typography>
            <Stack direction="row" justifyContent="center" alignItems="flex-end" gap="8px">
              <RHFTextField
                name="printing3DModel"
                label={''}
                variant="outlined"
                size="small"
                inputProps={{
                  onInput: handleNumberFieldInputValidate,
                  maxLength: 28,
                }}
                onBlur={onBlur}
                onChange={onChange}
                InputProps={{
                  startAdornment: (
                    <Typography variant="body2" sx={{ paddingRight: '9px' }}>
                      JPY
                    </Typography>
                  ),
                }}
                sx={{ width: '260px' }}
              />
              <Typography variant="subtitle1" color="text.secondary">
                /File
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Typography variant="h6">Finish</Typography>
            <Stack gap="16px">
              <Stack gap="8px">
                <Typography variant="subtitle1">Basic</Typography>
                <Stack direction="row" justifyContent="center" alignItems="flex-end" gap="8px">
                  <RHFTextField
                    name="basicFinishing"
                    label={''}
                    value={0}
                    variant="outlined"
                    size="small"
                    onBlur={onBlur}
                    onChange={onChange}
                    InputProps={{
                      startAdornment: (
                        <Typography variant="body2" sx={{ paddingRight: '9px' }}>
                          JPY
                        </Typography>
                      ),
                    }}
                    disabled={true}
                    sx={{ width: '260px' }}
                  />
                  <Typography variant="subtitle1" color="text.secondary">
                    /Case
                  </Typography>
                </Stack>
              </Stack>
              <Stack gap="8px">
                <Typography variant="subtitle1">Premium</Typography>
                <Stack direction="row" justifyContent="center" alignItems="flex-end" gap="8px">
                  <RHFTextField
                    name="premiumFinishing"
                    label={''}
                    variant="outlined"
                    size="small"
                    onBlur={onBlur}
                    onChange={onChange}
                    inputProps={{
                      onInput: handleNumberFieldInputValidate,
                      maxLength: 28,
                    }}
                    InputProps={{
                      startAdornment: (
                        <Typography variant="body2" sx={{ paddingRight: '9px' }}>
                          JPY
                        </Typography>
                      ),
                    }}
                    sx={{ width: '260px' }}
                  />
                  <Typography variant="subtitle1" color="text.secondary">
                    /Case
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Hook (Nob)</Typography>
            <Stack direction="row" justifyContent="center" alignItems="flex-end" gap="8px">
              <RHFTextField
                name="hook"
                label={''}
                variant="outlined"
                size="small"
                onBlur={onBlur}
                onChange={onChange}
                inputProps={{
                  onInput: handleNumberFieldInputValidate,
                  maxLength: 28,
                }}
                InputProps={{
                  startAdornment: (
                    <Typography variant="body2" sx={{ paddingRight: '9px' }}>
                      JPY
                    </Typography>
                  ),
                }}
                sx={{ width: '260px' }}
              />
              <Typography variant="subtitle1" color="text.secondary">
                /Case
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Typography variant="h6">Set shipping fee</Typography>
            <Stack gap="16px">
              <Stack gap="8px">
                <Typography variant="subtitle1">Current shipping fee</Typography>
                <Typography variant="body1" color="text.secondary">
                  {`JPY ${optionPrices?.shipping}`}
                </Typography>
              </Stack>
              <Stack gap="8px">
                <Typography variant="subtitle1">New shipping fee</Typography>
                <RHFTextField
                  name="shipping"
                  label={''}
                  variant="outlined"
                  size="small"
                  onBlur={onBlur}
                  onChange={onChange}
                  inputProps={{
                    onInput: handleNumberFieldInputValidate,
                    maxLength: 28,
                  }}
                  InputProps={{
                    startAdornment: (
                      <Typography variant="body2" sx={{ paddingRight: '9px' }}>
                        JPY
                      </Typography>
                    ),
                  }}
                  sx={{ width: '316px' }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ mt: '60px' }} justifyContent="flex-end">
          <Button variant="contained" color="primary" type="submit" size="large">
            Update
          </Button>
        </Stack>
        <LeaveSiteDialogue blocker={blocker} />
      </Stack>
    </FormProvider>
  );
};

export default AdditionalOptions;
