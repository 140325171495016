import React from 'react';
import { useFormik } from 'formik';

import { designManufactureCombination, OptionValueRequest } from '../../../client';
import CustomDialog from '../../../components/dialog/CustomDialog';
import { useProductActions } from '../../../recoil/products/useProduct';
import { AddProductDialogContent } from './AddProductDialogContent';

const DEFAULT_OPTION_VALUE: OptionValueRequest = {
  optionId: '',
  value: '',
};

export const NEW_PRODUCT_INIT_STATE = {
  price: 0,
  priceUnit: 'JPY',
  prosthesis: DEFAULT_OPTION_VALUE,
  method: DEFAULT_OPTION_VALUE,
  material: DEFAULT_OPTION_VALUE,
  shape: DEFAULT_OPTION_VALUE,
  designManufactureCombination: 'DESIGN_AND_MANUFACTURE' as designManufactureCombination,
  colorCode: 'string',
  requireScanBodyAndImplantInfo: false,
};

interface IAddProductDialog {
  open: boolean;
  onCloseDialog: () => void;
  btnActionText: string;
  btnAction: () => void;
  productForEdit: Array<unknown>;
}
export default function AddProductDialog({
  open,
  onCloseDialog,
  btnActionText,
  btnAction,
  productForEdit,
}: IAddProductDialog) {
  const { useProductItemMutation } = useProductActions();
  const { mutateAsync } = useProductItemMutation();

  const formik = useFormik({
    initialValues: NEW_PRODUCT_INIT_STATE,
    onSubmit: (values) => {
      console.log(values);
      mutateAsync(values).then((res) => {
        onCloseDialog();
        formik.resetForm();
      });
    },
  });
  const { values, handleSubmit } = formik;
  const btnDisabled =
    values.prosthesis.value === '' || values.material.value === '' || values.price === 0 || values.shape.value === '';

  return (
    <form onSubmit={handleSubmit} id="product-creation-form">
      <CustomDialog
        title={'New Product'}
        open={open}
        handleClose={onCloseDialog}
        // dialogActions={false}
        btnActionText={btnActionText}
        btnDisabled={btnDisabled}
        btnType="submit"
        formId={'product-creation-form'}
        // dialogActionsStyle={{ marginLeft: 7, maxWidth: '66%', display: 'flex', justifyContent: 'space-between' }}
      >
        <AddProductDialogContent
          // productMaterials={allProductMaterials}
          // productMethods={allProductMethods}
          // productShapes={allProductShapes}
          // productTypes={allProductTypes}
          // deleteSelectedEntityById={(id, key) => {}}
          // postSelectedEntity={(value, key, setOpenAddDlg, setAddNewEntity) => {}}
          // setOpenAddEditDlg={setOpenAddEditDlg}
          // id={'id'}
          onClose={onCloseDialog}
          formik={formik}
          // productForEdit={productForEdit}
          // productList={productList}
          // setProductList={setProductList}
          // setNewProductForEdit={setNewProductForEdit}
          // setIsDuplicate={setIsDuplicate}
          // isDuplicate={isDuplicate}
        />
      </CustomDialog>
    </form>
  );
}
