import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import axios, { AxiosError, isAxiosError } from 'axios';

import { LoadingScreen } from './components/loading/LoadingScreen';
import { AppLayout } from './components/shared/AppLayout/AppLayout';
import { adminLoginApi, getUserData } from './recoil/auth/auth.api';

import './assets/css/styles.min.css';

export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  // const { authState } = useAuth();
  // const { isAuthenticated } = authState;
  // const checkUserAndPermissions = useCallback(async () => {
  //   try {
  //     const { data } = await getUserData();
  //
  //     const connectGeneralRole = data?.roles.find((role) => role === 'ci_medical:user');
  //     if (!connectGeneralRole) window.location.replace(process.env.REACT_APP_CLOUD_CLIENT_URL as string);
  //
  //     if (!data || !data.roles || !data.permissions) return;
  //     const connectRole = data.roles.find((role) => role !== 'ci_medical:user') as keyof typeof ROLE;
  //     setUserRoleConfig({ role: connectRole, permissions: data.permissions });
  //   } catch (err) {
  //     if (err instanceof HTTPError && err?.response?.status === 400) {
  //       // TODO: Will create user on serverside. no request required.
  //       // 완전 임시 tenant 처리. 수정 예정
  //       const { data } = await getCloudUser();
  //       if (data.tenantId !== 'ci_medical') {
  //         window.location.replace(process.env.REACT_APP_CLOUD_CLIENT_URL as string);
  //       } else await handleNewUser();
  //     }
  //   }
  // }, [handleNewUser]);

  useEffect(() => {
    const initialize = async () => {
      try {
        const newAccessToken = await adminLoginApi();
        axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        const { data } = await getUserData();
        if (!data) {
          console.error('No user data found');
          return;
        }
        setIsLoading(false);
      } catch (e) {
        if (isAxiosError(e)) {
          const axiosError = e as AxiosError;
          if (
            axiosError.response?.status === 400 &&
            (axiosError.response?.data as { errorCode: string }).errorCode === 'CONNECT:USER_NOT_ALLOWED'
          ) {
            window.location.replace(process.env.REACT_APP_CONNECT_CLIENT_URL as string);
          }
        }
        console.error('Initialization failed', e);
      }
    };
    initialize();
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <AppLayout />
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}
