import { QueryFunctionContext } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';

import {
  GetOrganizationMembersResponse,
  GetOrganizationsResponse,
  GetProductSupplyPricesResponse,
  ResponseDtoBackOfficeOrganizationResponse,
  ResponseDtoListOrganizationWithCapacity,
  UpdateOrganizationTypeData,
  UpdateProductSupplyPricesData,
} from '../../client';
import { PartnerQueryParamsDTO } from '../dto/queryParams/queryParams.dto';
import { PaginatedResponseDTO } from '../dto/response/paginated.dto';
import { PartnerDTO } from '../dto/response/partner.dto';
import { SuccessResponseDTO } from '../dto/response/success.dto';

const GET_ALL_USER_ACCOUNTS = 'admin/user-account/users';
const UPDATE_CLINIC_USERS_TO_LAB_USERS = 'admin/user-account/user-type';
const DELETE_LAB_USERS = 'admin/user-account';
// Internal
const ORGANIZATION_LIST = 'connect/back-office/admin/organizations';
const ORGANIZATION_SUPPLY_LIST = 'connect/back-office/admin/products/supply';

export const getOrganizationList = async ({ queryKey }: QueryFunctionContext): Promise<GetOrganizationsResponse> => {
  const [_key, page, size, sort, organizationType, searchKeyword] = queryKey;
  return await axios
    .get(`${ORGANIZATION_LIST}?sort=${(sort as Array<string>).join(',')}`, {
      params: { page, size, organizationType, searchKeyword },
    })
    .then((res) => res.data);
};

export const getOrganizationDetails = async ({
  queryKey,
}: QueryFunctionContext): Promise<ResponseDtoBackOfficeOrganizationResponse> => {
  const [_key, organizationId] = queryKey;
  return await axios.get(`${ORGANIZATION_LIST}/${organizationId}`).then((res) => res.data);
};

export const getOrganizationMemberList = async ({
  queryKey,
}: QueryFunctionContext): Promise<GetOrganizationMembersResponse> => {
  const [_key, organizationId, page, size, sort] = queryKey;
  return await axios
    .get(`${ORGANIZATION_LIST}/${organizationId}/members?sort=${(sort as Array<string>).join(',')}`, {
      params: { page, size },
    })
    .then((res) => res.data);
};

export const patchOrganizationType = async ({ organizationId, requestBody }: UpdateOrganizationTypeData) => {
  return await axios.patch(`${ORGANIZATION_LIST}/${organizationId}/type`, { ...requestBody }).then((res) => res.data);
};

export const getOrganizationSupplyList = async ({
  queryKey,
}: QueryFunctionContext): Promise<GetProductSupplyPricesResponse> => {
  const [_key, organizationId, page, size] = queryKey;
  return await axios
    .get(`${ORGANIZATION_SUPPLY_LIST}/${organizationId}`, {
      params: { page, size },
    })
    .then((res) => res.data);
};

export const patchOrganizationSupplyList = async ({ organizationId, requestBody }: UpdateProductSupplyPricesData) => {
  return await axios.patch(`${ORGANIZATION_SUPPLY_LIST}/${organizationId}`, { ...requestBody }).then((res) => res.data);
};

export const getAvailableOrganizationList = async (
  orderId: string,
): Promise<ResponseDtoListOrganizationWithCapacity> => {
  return await axios.get(`connect/back-office/admin/orders/${orderId}/available`).then((res) => res.data);
};

//
export const getAllUserAccounts = (
  queryParams: PartnerQueryParamsDTO,
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<PartnerDTO>>>> => {
  return axios.get(
    `${GET_ALL_USER_ACCOUNTS}?userType=${queryParams.userType}&search=${queryParams.searchValue}&searchBy=${queryParams.searchBy}`,
  );
};
export const updateClinicUserToLabUser = (ids: string): Promise<AxiosResponse> => {
  return axios.patch(`${UPDATE_CLINIC_USERS_TO_LAB_USERS}?ids=${ids}`);
};

export const deleteLabUser = (id: string): Promise<AxiosResponse> => {
  return axios.delete(`${DELETE_LAB_USERS}/${id}`);
};
