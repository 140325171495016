import { Suspense } from 'react';
import { Card, CircularProgress, Stack } from '@mui/material';

import { BackOfficeOrganizationResponse } from '../../../client';
import InformationSection from './InformationSection';
import MemberListTable from './MemberListTable';

type InformationTabPropsType = {
  organizationInfo: BackOfficeOrganizationResponse;
};

export default function InformationTab({ organizationInfo }: InformationTabPropsType) {
  return (
    <Stack direction="row" gap="20px">
      <Card sx={{ padding: '32px 24px', width: '531px' }}>
        <InformationSection organizationInfo={organizationInfo} />
      </Card>

      <Card sx={{ padding: '32px 24px', width: '727px' }}>
        <Suspense fallback={<CircularProgress />}>
          <MemberListTable />
        </Suspense>
      </Card>
    </Stack>
  );
}
