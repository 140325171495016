import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { GlobalAuthProvider } from '@imago-cloud/auth-client';
import axios from 'axios';
import { RecoilRoot } from 'recoil';

import App from './App';
import setupAxiosInterceptors from './config/axios-intercepter';
import reportWebVitals from './reportWebVitals';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

setupAxiosInterceptors();
export const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        {/*<GlobalAuthProvider instance={axios}>*/}
        <App />
        {/*</GlobalAuthProvider>*/}
      </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
