import { QueryFunctionContext } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';

import {
  CreateProductOptionRequest,
  CreateProductRequest,
  CreateProductsAvailableOptionResponse,
  GetProductsAvailableOptionResponse,
  ResponseDtoAdditionalPrice,
  ResponseDtoPageProductDetail,
  UpdateProductPriceStatusRequest,
} from '../../client';
import { NewProductDTO } from '../dto/request/newProduct.dto';
import { UpdateRemakeProductDTO } from '../dto/request/updateRemakeProduct.dto';
import { PaginatedResponseDTO } from '../dto/response/paginated.dto';
import { ProductResponseDTO } from '../dto/response/product.dto';
import { ProductAdditionalOption, ProductRemakePolicyDTO } from '../dto/response/productAdditionalOption.dto';
import { SuccessResponseDTO } from '../dto/response/success.dto';
import { SupplyDTO, SupplyListByLabDTO } from '../dto/response/supply.dto';
import { SingleProductDto } from '../dto/states/productsState.dto';

const ALL_PRODUCT = 'connect/back-office/admin/products';
const ALL_PRODUCT_OPTIONS = 'connect/back-office/admin/products/options';

const GET_PRODUCT_METHOD = 'admin/products/methods';
const GET_PRODUCT_MATEIAL = 'admin/products/materials';
const GET_PRODUCT_SHAPES = 'admin/products/shape';
const CREATE_PRODUCT = 'admin/products/productDetails';
const UPDATE_MULTIPLE_PRODUCTS = 'admin/products/multiple-productDetails';
const GET_PRODUCT = 'admin/products/product-Details';

const DELETE_PRODUCT_METHOD = 'admin/products/method';
const DELETE_PRODUCT_MATEIAL = 'admin/products/material';
const DELETE_PRODUCT_SHAPES = 'admin/products/shape';

const POST_PRODUCT_METHOD = 'admin/products/method';
const POST_PRODUCT_MATERIAL = 'admin/products/material';

const GET_ADMIN_SUPPLY_URL = 'admin/product-supply';

const GET_ADMIN_ADDITIONAL_OPTIONS = 'admin/product-options';
const GET_REMAKE_POLICY_URL = 'admin/products/remake-policy';

const UPDATE_PRODUCT_REMAKE = 'admin/products/productDetails/remake';
const GET_PRODUCT_SUPPLY_LIST_BY_LAB = 'admin/product-supply';

//get
export const getProductList = ({ queryKey }: QueryFunctionContext): Promise<ResponseDtoPageProductDetail> => {
  const [_key, status, page, size, sort] = queryKey;
  return axios
    .get(`${ALL_PRODUCT}?page=${page}&size=${size}&status=${status}&sort=${(sort as Array<string>).join(',')}`, {})
    .then((res) => res.data);
};

export const getProductOptions = async ({
  queryKey,
}: QueryFunctionContext): Promise<GetProductsAvailableOptionResponse> => {
  const [_key, option] = queryKey;
  return await axios.get(`${ALL_PRODUCT_OPTIONS}?optionGroup=${option}`).then((res) => res.data);
};

export const postProductOptions = async (
  body: CreateProductOptionRequest,
): Promise<CreateProductsAvailableOptionResponse> => {
  return await axios.post(ALL_PRODUCT_OPTIONS, { ...body }).then((res) => res.data);
};

export const postProductItem = async (body: CreateProductRequest) => {
  return axios.post(ALL_PRODUCT, { ...body }).then((res) => res.data);
};

export const patchProductItemLifeCycle = async (body: UpdateProductPriceStatusRequest) => {
  return axios.patch(ALL_PRODUCT, { ...body }).then((res) => res.data);
};

export const deleteProductItem = async (productId: string) => {
  return axios.delete(`${ALL_PRODUCT}/${productId}`).then((res) => res.data);
};

///
export const getProductMethods = (): Promise<
  AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>
> => {
  return axios.get(`${GET_PRODUCT_METHOD}`);
};

export const getProductMaterials = (): Promise<
  AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>
> => {
  return axios.get(`${GET_PRODUCT_MATEIAL}`);
};

export const getProductShapes = (): Promise<
  AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>
> => {
  return axios.get(`${GET_PRODUCT_SHAPES}`);
};

//delete

export const deleteProductShapesById = (
  id: string,
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>> => {
  return axios.delete(`${DELETE_PRODUCT_SHAPES}/${id}`);
};

export const deleteProductMaterialsById = (
  id: string,
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>> => {
  return axios.delete(`${DELETE_PRODUCT_MATEIAL}/${id}`);
};

export const deleteProductMethodsById = (
  id: string,
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>> => {
  return axios.delete(`${DELETE_PRODUCT_METHOD}/${id}`);
};

export const deleteAllProductTypesById = (
  id: string,
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>> => {
  return axios.delete(`${ALL_PRODUCT}/${id}`);
};

///post

interface PostDataDto {
  key: string;
  value: string;
}
export const postAllProductTypes = (
  data: PostDataDto,
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>> => {
  return axios.post(`${ALL_PRODUCT}`, data);
};

export const postProductMethods = (
  data: PostDataDto,
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>> => {
  return axios.post(`${POST_PRODUCT_METHOD}`, data);
};

export const postProductMaterials = (
  data: PostDataDto,
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>> => {
  return axios.post(`${POST_PRODUCT_MATERIAL}`, data);
};

export const postProductShapes = (
  data: PostDataDto,
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>> => {
  return axios.post(`${GET_PRODUCT_SHAPES}`, data);
};

//Create Product

export const createProduct = (
  data: NewProductDTO,
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SingleProductDto>>>> => {
  return axios.post(`${CREATE_PRODUCT}`, data);
};

export const getAllProductsForListing = (): Promise<
  AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<ProductResponseDTO>>>
> => {
  return axios.get(`${GET_PRODUCT}`);
};

export const productDeleteById = (
  id: string,
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<ProductResponseDTO>>>> => {
  return axios.delete(`${CREATE_PRODUCT}/${id}`);
};

export const getProductById = (id: string): Promise<AxiosResponse<SuccessResponseDTO<ProductResponseDTO>>> => {
  return axios.get(`${CREATE_PRODUCT}/${id}`);
};

export const updateProductById = (data: any): Promise<AxiosResponse<SuccessResponseDTO<ProductResponseDTO>>> => {
  return axios.patch(`${UPDATE_MULTIPLE_PRODUCTS}`, data);
};
//update the order remake
export const updateProductRemake = (
  arrayforUpdate: UpdateRemakeProductDTO[],
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SupplyDTO>>>> => {
  return axios.patch(`${UPDATE_PRODUCT_REMAKE}`, {
    dataToRemake: arrayforUpdate,
  });
};

// supply Products

export const getAdminSupplyList = (): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SupplyDTO>>>> => {
  return axios.get(`${GET_ADMIN_SUPPLY_URL}`);
};

// product additional-options
export const getAdminProductAdditionalOption = (): Promise<
  AxiosResponse<SuccessResponseDTO<ProductAdditionalOption>>
> => {
  return axios.get(`${GET_ADMIN_ADDITIONAL_OPTIONS}`);
};

// product additional-options
export const getProductRemakePolicy = (): Promise<AxiosResponse<SuccessResponseDTO<ProductRemakePolicyDTO>>> => {
  return axios.get(`${GET_REMAKE_POLICY_URL}`);
};

interface arrayForUpdateDto {
  product: string;
  price: number;
  labOrganization: string;
  isAvailable: boolean;
}

export const updateAdminSupplyList = (
  arrayforUpdate: arrayForUpdateDto[],
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SupplyDTO>>>> => {
  return axios.patch(`${GET_ADMIN_SUPPLY_URL}`, {
    productSupply: arrayforUpdate,
  });
};
export const getAdminSupplyListByLab = (
  id: string,
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<SupplyListByLabDTO>>>> => {
  return axios.get(`${GET_PRODUCT_SUPPLY_LIST_BY_LAB}?labOrganization=${id}`);
};

export const updateProductAdditionalOption = (
  dataToUpdate: ProductAdditionalOption,
): Promise<AxiosResponse<SuccessResponseDTO<ProductAdditionalOption>>> => {
  return axios.patch(`${GET_ADMIN_ADDITIONAL_OPTIONS}/${dataToUpdate._id}`, { ...dataToUpdate, _id: undefined });
};

export const updateProductRemakePolicy = (
  dataToUpdate: ProductAdditionalOption,
): Promise<AxiosResponse<SuccessResponseDTO<ProductRemakePolicyDTO>>> => {
  return axios.patch(`${GET_REMAKE_POLICY_URL}/${dataToUpdate._id}`, { ...dataToUpdate, _id: undefined });
};

// additonal options
export const getAdditionalOptionPrices = (): Promise<ResponseDtoAdditionalPrice> => {
  return axios.get('connect/back-office/admin/additional-prices').then((res) => res.data);
};
