import React, { useEffect, useState } from 'react';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ProductDetail } from '../../../client';
import CustomDataTable, { ITableColumnTypes } from '../../../components/table/DataTable';
import { TableLoading } from '../../../components/table/TableLoading';
import { TableNoData } from '../../../components/table/TableNoData';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';
import { useProductActions } from '../../../recoil/products/useProduct';
import { convertOrderRangeText } from '../../Order/Helper';
import { ProductPublishAndDraftDialogue } from './ProductPublishAndDraftDialogue';
import { ProductStatusType } from './ProductsListPage';
import TableRowActions from './TableRowActions';

interface StyledIStatusProps {
  status: string;
}

const tableColumn: ITableColumnTypes[] = [
  { id: 'prosthesis', label: 'Prosthesis', minWidth: 156, alignCol: 'left' },
  { id: 'method', label: 'Method', minWidth: 160, alignCol: 'left' },
  { id: 'material', label: 'Material', minWidth: 200, alignCol: 'left' },
  { id: 'shape', label: 'Shape', minWidth: 156, alignCol: 'center' },
  { id: 'range', label: 'Range', minWidth: 238, alignCol: 'left' },
  {
    id: 'uniquePrice',
    label: 'Unique Price',
    minWidth: 200,
    alignCol: 'center',
  },
  { id: 'actions', label: '', minWidth: 68, alignCol: 'center' },
];

const TO_DRAFT = {
  title: 'Move to draft',
  desc:
    'Selected products will move to to the draft list. \n' +
    "Once moved, they will no longer be visible on the user's screen.\n" +
    'Please be cautious when moving them.',
};

const TO_PUBLISH = {
  title: 'Publish products',
  desc:
    'Ready to publish the selected products?\n' +
    'They will appear instantly to users, so make sure everything is accurate before you proceed.',
};

interface ITableProps {
  activeTab: ProductStatusType;
  productList: Array<ProductDetail>;
  setProductList: any;
  // pagination
  dense: boolean;
  page: number;
  totalElement: number;
  rowsPerPage: number;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeDense: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isPending: boolean;
}

// Publish / Draft dialog 관련
export default function ProductsListTable({
  activeTab,
  productList,
  // isProductEditingOrLoading,
  setProductList,
  // pagination
  dense,
  page,
  totalElement,
  rowsPerPage,
  onChangePage,
  onChangeDense,
  onChangeRowsPerPage,
  isPending,
}: Readonly<ITableProps>) {
  const { useProductItemLifeCycleMutation, useProductItemDelete } = useProductActions();
  const { mutateAsync, variables } = useProductItemLifeCycleMutation();
  const { mutateAsync: deleteProductItem } = useProductItemDelete();

  const { open, handleOpenDialogue, handleCloseDialogue } = useDialogue();
  const [checkedItemList, setCheckedItemList] = useState<ProductDetail[]>([]);
  const isDraftedTab = activeTab === 'DRAFTED';

  const handleItemCheckClick = (product: ProductDetail) => {
    setCheckedItemList((prev) => {
      const isChecked = prev.some((item) => item.id === product.id);
      if (isChecked) {
        return prev.filter((item) => item.id !== product.id);
      } else {
        return [...prev, product];
      }
    });
  };
  const handleAllItemClick = () => {
    if (productList.length === checkedItemList.length) {
      setCheckedItemList([]);
    } else {
      setCheckedItemList(productList);
    }
  };

  const handlePublishClick = () => {
    const idArray = checkedItemList.map((item) => item.id);
    mutateAsync({ targetProductIds: idArray, newStatus: 'PUBLISHED' });
    setCheckedItemList([]);
  };
  const handleDraftClick = () => {
    const idArray = checkedItemList.map((item) => item.id);
    mutateAsync({ targetProductIds: idArray, newStatus: 'DRAFTED' });
    setCheckedItemList([]);
  };

  const handleDeleteClick = async (productId: string) => {
    await deleteProductItem(productId);
  };

  useEffect(() => {
    setCheckedItemList([]);
  }, [activeTab]);

  return (
    <>
      <CustomDataTable
        activeTab={activeTab}
        tableColumn={tableColumn}
        checkedItemList={checkedItemList}
        onAllItemClick={handleAllItemClick}
        rowsPerPage={rowsPerPage}
        onOpenConfirmDialog={handleOpenDialogue}
        dense={dense}
        page={page}
        totalElement={totalElement}
        onChangePage={onChangePage}
        onChangeDense={onChangeDense}
        onChangeRowsPerPage={onChangeRowsPerPage}
        // onDeleteClick={handleDeleteClick}
        // onPublishClick={handlePublishClick}
        // onDraftClick={handleDraftClick}
      >
        {isPending ? (
          <TableLoading style={{ height: '344px' }} colSpan={tableColumn.length} />
        ) : productList && productList.length > 0 ? (
          productList?.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell align="right">
                <Checkbox
                  onClick={() => handleItemCheckClick(row)}
                  checked={checkedItemList.some((item) => item.id === row.id)}
                />
              </TableCell>
              <TableCell align="left">{row.prosthesis}</TableCell>
              <TableCell align="left">{row.method}</TableCell>
              <TableCell align="left">{row.material}</TableCell>
              <TableCell align="center">{row.shape}</TableCell>
              {/*// @ts-ignore*/}
              <TableCell align="left">{convertOrderRangeText(row.designManufactureCombination)}</TableCell>
              <TableCell align="center">{`JPY ${row?.price.toLocaleString()}`}</TableCell>
              <TableCell align="center">
                <TableRowActions
                  setOpenAddEditDlg={() => {}}
                  row={row}
                  getProductForDeleteHandler={handleDeleteClick}
                  setProductList={setProductList}
                  productList={productList}
                />
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableNoData style={{ height: '344px' }} colSpan={tableColumn.length} content={'No product list to show'} />
        )}
      </CustomDataTable>

      <ProductPublishAndDraftDialogue
        open={open}
        onClose={handleCloseDialogue}
        onConfirmAction={isDraftedTab ? handlePublishClick : handleDraftClick}
        checkedItemList={checkedItemList}
        {...(isDraftedTab ? TO_PUBLISH : TO_DRAFT)}
      />

      {/*{open && (*/}
      {/*<CustomAlertDialog*/}
      {/*  {...(isDraftedTab ? TO_PUBLISH : TO_DRAFT)}*/}
      {/*  open={open}*/}
      {/*  handleClose={handleCloseDialogue}*/}
      {/*  btnAction={isDraftedTab ? handlePublishClick : handleDraftClick}*/}
      {/*/>*/}
      {/*)}*/}
    </>
  );
}
