import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Chip, Stack, TextField } from '@mui/material';
import humanizeString from 'humanize-string';

import { optionGroup, ProductOption } from '../../../client';
import CustomDialog from '../../../components/dialog/CustomDialog';
import { useProductActions } from '../../../recoil/products/useProduct';

interface IEditOptionDialogProps {
  open: boolean;
  onCloseDialogue: () => void;
  selectedEditOption: optionGroup;
  userSelectedEditOptionList: Array<ProductOption>;
}

export default function EditOptionDialog({
  open,
  onCloseDialogue,
  selectedEditOption,
  userSelectedEditOptionList,
}: Readonly<IEditOptionDialogProps>) {
  const { useProductOptionMutation } = useProductActions();
  const { mutateAsync } = useProductOptionMutation();
  const [editMode, setEditMode] = useState<'DELETE' | 'ADD' | 'MODERATE'>('MODERATE');
  const [userTypedValue, setUserTypedValue] = useState('');

  const handleAddOptionClick = async () => {
    const res = await mutateAsync({
      optionGroup: selectedEditOption,
      value: userTypedValue,
    });
    if (res.success) {
      setUserTypedValue('');
    }
  };

  return (
    <CustomDialog
      title={`Edit ${humanizeString(selectedEditOption)}`}
      open={open}
      handleClose={() => {
        setEditMode('MODERATE');
        onCloseDialogue();
      }}
      btnActionText="Apply changes"
      btnAction={() => {}}
      // dialogActions
    >
      <Stack gap="24px">
        <Stack direction="row" alignItems="center" flexWrap="wrap" gap="8px">
          {userSelectedEditOptionList?.map((item) => {
            return (
              <Chip
                key={item.id}
                icon={
                  editMode === 'DELETE' ? (
                    <CloseIcon
                      sx={{
                        ml: '-20px !important',
                        mr: '5px !important',
                        background: '#c6c8cb',
                        color: 'white !important',
                        p: 0.1,
                        fontSize: 15,
                        borderRadius: 100,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        // todo: mutateAsync delete - api not yet
                      }}
                    />
                  ) : undefined
                }
                sx={{
                  gap: 2,
                  bgcolor: '#ffffff',
                  border: '1px solid #c6c8cb',
                  // position: "absolute",
                  // left: "50%",
                  // transform: "translateX(-50%)",
                  // bottom: -18,
                  zIndex: 1,
                  flexDirection: 'row-reverse',
                }}
                label={item.value}
              />
            );
          })}
        </Stack>

        <Stack sx={{ width: '100%' }} justifyContent="space-between" alignItems="center" gap="8px">
          <Stack
            sx={{ width: '100%', height: '40px' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="8px"
          >
            <Button onClick={() => setEditMode('DELETE')} sx={{ width: '100%', height: '40px' }} variant="outlined">
              Delete
            </Button>
            <Button onClick={() => setEditMode('ADD')} sx={{ width: '100%', height: '40px' }} variant="outlined">
              + Add
            </Button>
          </Stack>
          {editMode === 'ADD' && (
            <Stack direction="row" justifyContent={'space-between'} alignItems="center">
              <TextField onChange={(e) => setUserTypedValue(e.target.value)} value={userTypedValue} />
              <Button onClick={handleAddOptionClick} disabled={userTypedValue === ''}>
                Add option
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </CustomDialog>
  );
}
