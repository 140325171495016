import * as React from 'react';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, FormControl, Stack, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import humanizeString from 'humanize-string';

import { BackOfficeOrganizationResponse, organizationType } from '../../../client';
import { useOrganizationActions } from '../../../recoil/organizations/useOrganizations';
import useTopAlert from '../../../recoil/topAlert/useTopAlert';

const ORGANIZATION_TYPE_LIST = [
  { id: 'user', label: 'User', value: 'USER' },
  { id: 'partnerLab', label: 'Partner Lab', value: 'PARTNER_LAB' },
];

interface Props {
  organizationInfo: BackOfficeOrganizationResponse;
}

export default function InformationSection({ organizationInfo }: Props) {
  const { setTopAlert } = useTopAlert();

  const { id, name, address, businessPhone, organizationType, corporateNumber, certificateOfIncorporation } =
    organizationInfo;
  const { useOrganizationTypeMutation } = useOrganizationActions();
  const { mutateAsync, isPending } = useOrganizationTypeMutation();

  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedOrgRole, setSelectedOrgRole] = useState<organizationType>(organizationType);

  const handleOrgRoleChange = (e: SelectChangeEvent<organizationType>) => {
    const { value } = e.target;
    setSelectedOrgRole(value as organizationType);
  };

  const handleButtonClick = async () => {
    if (isEditMode) {
      if (organizationType !== selectedOrgRole) {
        await mutateAsync(
          { organizationId: id, requestBody: { organizationType: selectedOrgRole } },
          {
            onSuccess: () => {
              setTopAlert({ open: true, variant: 'filled', severity: 'success', description: 'Updated successfully' });
            },
          },
        );
      }
    }
    setIsEditMode((prev) => !prev);
  };

  useEffect(() => {
    setSelectedOrgRole(organizationType);
  }, [isEditMode]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant={'h5'}>Information</Typography>
        <LoadingButton loading={isPending} variant="outlined" onClick={handleButtonClick}>
          {isEditMode ? 'Update' : 'Edit'}
        </LoadingButton>
      </Stack>
      <Stack gap="8px">
        <Box>
          <Typography variant={'subtitle2'}>Organization name</Typography>
          <Typography variant={'body2'} color={'text.secondary'}>
            {name}
          </Typography>
        </Box>
        <Box>
          <Typography variant={'subtitle2'}>Organization address</Typography>
          <Typography
            variant={'body2'}
            color={'text.secondary'}
          >{`${address.address} ${address.optionalAddress ?? ''}`}</Typography>
          <Typography variant={'body2'} color={'text.secondary'}>
            {' '}
            {`${address.suburb} ${address.state} ${address.country}`}
          </Typography>
          <Typography variant={'body2'} color={'text.secondary'}>
            {address.zipCode}
          </Typography>
        </Box>
        <Box>
          <Typography variant={'subtitle2'}>Organization phone</Typography>
          <Typography
            variant={'body2'}
            color={'text.secondary'}
          >{`+${businessPhone.countryCode} ${businessPhone.number}`}</Typography>
        </Box>
        <Box>
          <Typography variant={'subtitle2'}>Organization type</Typography>
          {isEditMode ? (
            <Box>
              <FormControl fullWidth>
                {/*<InputLabel id="demo-simple-select-label">Organization Type</InputLabel>*/}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedOrgRole}
                  // label="Organization Type"
                  onChange={handleOrgRoleChange}
                >
                  {ORGANIZATION_TYPE_LIST.map((role) => (
                    <MenuItem key={role.id} value={role.value}>
                      {role.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <Typography variant={'body2'} color={'text.secondary'}>
              {humanizeString(organizationType)}
            </Typography>
          )}
        </Box>
        <Box>
          <Typography variant={'subtitle2'}>Corporate number</Typography>
          <Typography variant={'body2'} color={'text.secondary'}>
            {corporateNumber}
          </Typography>
        </Box>
        <Stack>
          <Typography variant={'subtitle2'}>Certificate of incorporation</Typography>
          {certificateOfIncorporation?.fileUrl ? (
            <Stack sx={{ width: '483px', height: '301px' }}>
              <img
                alt="certificate-of-incorporation"
                src={certificateOfIncorporation?.fileUrl}
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              />
            </Stack>
          ) : (
            <Typography variant={'body2'} color={'text.secondary'}>
              No image
            </Typography>
          )}
        </Stack>
      </Stack>
    </>
  );
}
