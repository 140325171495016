import { useState } from 'react';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Iconify from '../../../components/iconify/Iconify';
import { ICloseButtonOnlyDialogueProps } from '../../../constants/helperHooks/useDialogue';

type RemakePolicyDialoguePropsType = ICloseButtonOnlyDialogueProps & {};

export const RemakePolicyDialogue = ({ open, onClose }: RemakePolicyDialoguePropsType) => {
  const [isEditing, setIsEditing] = useState(false);
  const content = 'ddd';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '800px !important',
          minWidth: '800px',
        },
      }}
    >
      <DialogTitle sx={{ padding: '24px 12px 24px 24px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Remake policy</Typography>
          <IconButton aria-label="close" onClick={onClose} color="default" size="medium">
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ height: '409px !important' }}>
        {content ? (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
          <Stack justifyContent="center" alignItems="center" gap="12px" sx={{ marginTop: '175.5px' }}>
            <Typography variant="h6" color="text.secondary">
              No Remake policy yet
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Start to create a remake policy with Edit button below.
            </Typography>
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ border: 'none !important' }}>
        {isEditing ? (
          <Stack direction="row" gap="12px" sx={{ minWidth: '76px' }}>
            <Button variant="outlined" color="inherit" size="medium">
              Discard
            </Button>
            <Button variant="contained" color="primary" size="medium" sx={{ minWidth: '64px' }}>
              Apply
            </Button>
          </Stack>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={() => setIsEditing(true)}
            sx={{ minWidth: '64px' }}
          >
            Edit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
