import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CircularProgress, Stack, Typography } from '@mui/material';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import { OrderDetailResponse } from '../../../client';
import Iconify from '../../../components/iconify/Iconify';
import { useSetPaginationFlag } from '../../../components/table/usePersistentPagination';
import { APP_ROUTES } from '../../../constants/AppRoutes';
import { useOrderDetailQuery } from '../../../recoil/order/useOrder';
import { OrderPrintRx } from '../PrintRx/printRx';
import ChatBox from './ChatWindow';
import { OrderDetailAction } from './OrderDetailAction';
import { OrderDetailInfo } from './OrderDetailInfo';
import { RemakeHistorySelect } from './RemakeHistorySelect';
import BoCustomSendbirdProvider from './Tabs/BoCustomSendbirdProvider';
import { OrderDetailTabs } from './Tabs/OrderDetailTabs';

const OrderDetailPage = () => {
  const navigate = useNavigate();
  const [creatingZipFileLoading, setCreatingZipFileLoading] = useState(false);
  const [isDownloadFilesCompleted, setIsDownloadFilesCompleted] = useState<boolean>(false);
  const [showInvoice, setShowInvoice] = React.useState<boolean>(false);
  const { id } = useParams();

  const { data, isLoading } = useOrderDetailQuery(id ?? '');
  const orderDetailInfo = data?.data as OrderDetailResponse;

  useSetPaginationFlag('orderDetail');

  const createDesignFilesIntoZipHandler = async () => {
    // setIsDownloadFilesCompleted(true);
    // if (ordersForEdit?.labDesigns) {
    //   let CREATING_URL_ARRAY_OF_UPLOADED_FILES: string[] | any = [];
    //   let combineAllArrayOfLAbDesigns = [
    //     ...ordersForEdit?.labDesigns.lower,
    //     ...ordersForEdit?.labDesigns?.upper,
    //     ...ordersForEdit?.labDesigns.design,
    //   ];
    //   let creatingFileObjectForZip = [];
    //   if (combineAllArrayOfLAbDesigns?.length) {
    //     for (let i = 0; i < combineAllArrayOfLAbDesigns.length; i++) {
    //       let gettingFile = await exportFile(
    //         combineAllArrayOfLAbDesigns[i].url,
    //         combineAllArrayOfLAbDesigns[i].fileName,
    //       );
    //       if (gettingFile) {
    //         creatingFileObjectForZip.push(gettingFile);
    //         console.warn('DeCompressing the file');
    //       }
    //       if (creatingFileObjectForZip.length === combineAllArrayOfLAbDesigns.length) {
    //         console.warn('Downloaing the zip file');
    //         await DOWNLOAD_FILES_IN_ZIP(
    //           creatingFileObjectForZip,
    //           `${ordersForEdit?.clinicOrganization?.name}_${ordersForEdit?.patientName}_${ordersForEdit?.orderNo}_Design`,
    //         );
    //         setIsDownloadFilesCompleted(false);
    //       }
    //     }
    //   }
    // console.log(combineAllArrayOfLAbDesigns, "combineAllArrayOfLAbDesigns");
    // const result = await DOWNLOAD_FILES_IN_ZIP(CREATING_URL_ARRAY_OF_UPLOADED_FILES, "LabDesigns.zip");
    // if (result) {
    //   setIsDownloadFilesCompleted(false);
    // } else {
    //   setIsDownloadFilesCompleted(false);
    // }
    // } else {
    //   setIsDownloadFilesCompleted(false);
    // }
  };

  const downloadPatientDataIntoZipHandler = async () => {
    // setCreatingZipFileLoading(true);
    // if (ordersForEdit?.documents || ordersForEdit?.otherFiles) {
    //   let CREATING_URL_ARRAY_OF_UPLOADED_FILES: {
    //     url: string;
    //     name: string;
    //   }[] = [];
    //   let patientImages = [];
    //   let patientOtherFiles = [];
    //   if (ordersForEdit.documents?.length) {
    //     patientImages = ordersForEdit.documents?.map((item: any) => {
    //       return { url: item.url, name: item.fileName };
    //     });
    //   }
    //
    //   if (ordersForEdit?.otherFiles?.length) {
    //     patientOtherFiles = ordersForEdit?.otherFiles?.map((item: any) => {
    //       return { url: item.imageUrl, name: item.fileName };
    //     });
    //   }
    //   CREATING_URL_ARRAY_OF_UPLOADED_FILES = [...patientImages, ...patientOtherFiles];
    //   let creatingFileObjectForZip = [];
    //   if (CREATING_URL_ARRAY_OF_UPLOADED_FILES?.length) {
    //     for (let i = 0; i < CREATING_URL_ARRAY_OF_UPLOADED_FILES.length; i++) {
    //       let gettingFile = await exportFile(
    //         CREATING_URL_ARRAY_OF_UPLOADED_FILES[i].url,
    //         CREATING_URL_ARRAY_OF_UPLOADED_FILES[i].name,
    //       );
    //       if (gettingFile) {
    //         console.warn('DeCompressing the file');
    //         creatingFileObjectForZip.push(gettingFile);
    //       }
    //       if (creatingFileObjectForZip.length === CREATING_URL_ARRAY_OF_UPLOADED_FILES.length) {
    //         console.warn('Downloaing the zip file');
    //         await DOWNLOAD_FILES_IN_ZIP(
    //           creatingFileObjectForZip,
    //           `${ordersForEdit?.clinicOrganization?.name}_${ordersForEdit?.patientName}_${ordersForEdit?.orderNo}_PatientData`,
    //         );
    //         setCreatingZipFileLoading(false);
    //       }
    //     }
    //   }
    // }
  };

  // useEffect(() => {
  //   console.log(orderDetailInfo);
  //   if (!orderDetailInfo) return navigate(APP_ROUTES.ORDER_LIST.ROUTE);
  // }, [id, orderDetailInfo]);

  return (
    <BoCustomSendbirdProvider chattingChannelUrl={orderDetailInfo.chattingChannelUrl}>
      <Stack sx={{ width: '100%', padding: '40px 40px 0 40px' }}>
        <Stack
          gap="20px"
          sx={{
            width: '100%',
            marginBottom: '20px',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="text"
            color="inherit"
            size="large"
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
            onClick={() => navigate(`/${APP_ROUTES.ORDER_LIST.ROUTE}`)}
            sx={{ alignSelf: 'flex-start' }}
          >
            Back
          </Button>
          {orderDetailInfo && <RemakeHistorySelect orderDetailInfo={orderDetailInfo} />}
        </Stack>
        {orderDetailInfo && (
          <Stack direction="column" gap="40px" sx={{ width: '100%' }}>
            <OrderDetailInfo
              orderDetailInfo={orderDetailInfo}
              // orignalOrderDateOfRemakeOrder={orignalOrderDateOfRemakeOrder}
            />
            <Stack
              direction="row"
              gap="32px"
              sx={{ justifyContent: 'space-between', alignItems: 'flex-start', paddingBottom: '8px', width: '100%' }}
            >
              <Stack sx={{ flexGrow: 1 }}>
                <OrderDetailTabs orderDetailInfo={orderDetailInfo} />
              </Stack>

              <Stack direction="column" gap="24px" sx={{ flexGrow: 1 }}>
                {orderDetailInfo ? <OrderDetailAction orderDetailInfo={orderDetailInfo} /> : <CircularProgress />}
                <Card sx={{ flexGrow: 1 }}>
                  <Stack direction="column" gap="20px" sx={{ padding: '32px 24px' }}>
                    <Typography variant="h5">Chat</Typography>
                    {orderDetailInfo.chattingChannelUrl ? (
                      <ChatBox chattingChannelUrl={orderDetailInfo.chattingChannelUrl} />
                    ) : (
                      <Stack>Chat will be created once the order is approved by a partner lab.</Stack>
                    )}
                  </Stack>
                </Card>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </BoCustomSendbirdProvider>
  );
};

export default OrderDetailPage;
