import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Stack, TableCell, TableRow, Typography } from '@mui/material';

import CustomDataTable from '../../../components/table/DataTable';
import useTable from '../../../components/table/useTable';
import { useOrganizationActions } from '../../../recoil/organizations/useOrganizations';
import MemberListTableActions from './MemberListTableActions';

const OrganizationMemberTableColumn = [
  { id: 'isManager', label: '', minWidth: 80, alignCol: 'center' },
  { id: 'email', label: 'Email', minWidth: 246, alignCol: 'left' },
  { id: 'username', label: 'Username', minWidth: 220, alignCol: 'left' },
  { id: 'action', label: '', minWidth: 68, alignCol: 'right' },
];

export default function MemberListTable() {
  const { id } = useParams();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    //
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
    setPage,
  } = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0, defaultOrderBy: 'id', defaultOrder: 'desc' });

  const { useOrganizationMemberList } = useOrganizationActions();
  const { data } = useOrganizationMemberList({
    organizationId: id as string,
    page: page + 1,
    size: rowsPerPage,
    sort: [order, orderBy],
  });
  const memberList = data.data?.content ?? [];
  const pageInfo = data.data;

  return (
    <Stack gap="16px">
      <Box>
        <Typography variant="h5">Member</Typography>
        <Typography variant="body2">{memberList.length} Member</Typography>
      </Box>

      <CustomDataTable
        tableColumn={OrganizationMemberTableColumn}
        // rowsLength={memberList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        totalElement={pageInfo?.totalElements ?? 0}
        dense={dense}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangeDense={onChangeDense}
      >
        {memberList.map(({ id, isManager, email, name }) => (
          <TableRow key={id}>
            <TableCell align="center">{isManager ? 'Manager' : ''}</TableCell>
            <TableCell align="left">{email}</TableCell>
            <TableCell align="left">{`${name.firstName} ${name.lastName}`}</TableCell>
            <TableCell align="left">
              <MemberListTableActions email={email} name={name} role={isManager ? 'MANAGER' : 'MEMBER'} />
            </TableCell>
          </TableRow>
        ))}
      </CustomDataTable>
    </Stack>
  );
}
