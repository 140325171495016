import '@sendbird/uikit-react/dist/index.css';
import GroupChannel from '@sendbird/uikit-react/GroupChannel';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';

import { getSendbirdSessionToken } from '../../../recoil/chat/chat.api';

function ChatWindow({ groupChannelUrl }: { groupChannelUrl: string }) {
  return (
    <div className="sendbird-app__wrap" style={{ width: '100%', height: '760px' }}>
      <div className="sendbird-app__conversation-wrap" style={{ width: '100%' }}>
        <GroupChannel channelUrl={groupChannelUrl} />
      </div>
    </div>
  );
}

interface ChatBoxProps {
  chattingChannelUrl: string;
}
export default function ChatBox({ chattingChannelUrl }: ChatBoxProps) {
  return <ChatWindow groupChannelUrl={chattingChannelUrl} />;
}
