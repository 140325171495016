import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

type RHFTextFieldPropType = TextFieldProps & {
  label: string;
  name: string;
  labelVisible?: boolean;
  rules?: any;
};

export default function RHFTextField({
  label,
  name,
  labelVisible = false,
  size = 'medium',
  rules,
  ...props
}: RHFTextFieldPropType) {
  const { control, watch, trigger } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
        <TextField
          name={name}
          size={size}
          fullWidth
          value={typeof value === 'number' && value === 0 ? '0' : value}
          onChange={onChange}
          onBlur={(e) => {
            onChange(e.target.value.trim());
            trigger(name);
          }}
          InputLabelProps={{
            shrink: labelVisible,
          }}
          placeholder={label}
          error={!!error}
          helperText={error?.message}
          {...props}
        />
      )}
    />
  );
}
