import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  List,
  ListItem,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FormikProps } from 'formik';

import { optionGroup, ProductOption } from '../../../client';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';
import { ProductResponseDTO } from '../../../recoil/dto/response/product.dto';
import { useProductActions } from '../../../recoil/products/useProduct';
import { NEW_PRODUCT_INIT_STATE } from './AddProductDialog';
import EditOptionDialog from './EditOptionDialog';

const SELECT_DESIGN_MANUFACTURE_COMBINATION_OPTION = [
  { label: 'Design only', value: 'DESIGN', id: '1' },
  { label: 'Design & Manufacture', value: 'DESIGN_AND_MANUFACTURE', id: '2' },
  { label: 'Manufacture only', value: 'MANUFACTURE', id: '3' },
];
interface SelectDto {
  value: string;
  label: string;
  id: string;
}

interface Iprops {
  onClose: () => void;
  formik: FormikProps<typeof NEW_PRODUCT_INIT_STATE>;
  productForEdit?: ProductResponseDTO;
}
export const AddProductDialogContent = ({ formik, onClose }: Iprops) => {
  const { values, errors, handleChange, handleBlur, touched, setFieldValue } = formik;

  const { useAllProductOptions } = useProductActions();
  const {
    queryLoading,
    prosthesisOption,
    methodOption,
    materialOption,
    shapeOption,
    // priceUnitOption,
    // colorCodeOption,
  } = useAllProductOptions();

  const { open, handleOpenDialogue, handleCloseDialogue } = useDialogue();
  const [selectedEditOption, setSelectedEditOption] = useState<optionGroup>('PROSTHESIS');

  const getUserSelectedEditOptionList = () => {
    if (selectedEditOption === 'PROSTHESIS') {
      return prosthesisOption;
    } else if (selectedEditOption === 'METHOD') {
      return methodOption;
    } else if (selectedEditOption === 'MATERIAL') {
      return materialOption;
    } else if (selectedEditOption === 'SHAPE') {
      return shapeOption;
    }
  };
  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
    optionList: Array<ProductOption>,
  ) => {
    const selectedOption = optionList?.find((item) => item.id === e.target.value);
    const formatted = { optionId: selectedOption?.id, value: selectedOption?.value };
    setFieldValue(fieldName, formatted);
  };

  if (queryLoading)
    return (
      <div>
        <Skeleton variant="text" width={'100%'} height={50} />
        <Skeleton variant="text" width={'100%'} height={50} />
        <Skeleton variant="text" width={'100%'} height={50} />
      </div>
    );

  return (
    <>
      <Box>
        <List
          sx={{
            '& li': { mb: 2 },
            '& p': { fontWeight: 400, fontFamily: '"Noto Sans", sans-serif !important', minWidth: 150, color: 'black' },
            '& .MuiFormControl-root': {},
          }}
        >
          <ListItem sx={{ gap: 2, alignItems: 'baseline' }}>
            <Typography>*Prosthesis</Typography>
            <TextField
              style={{ height: '4rem' }}
              select
              fullWidth
              id="prosthesis"
              name="prosthesis"
              label={'Product Type'}
              variant="outlined"
              value={values?.prosthesis.optionId}
              onChange={(e) => handleFieldChange(e, 'prosthesis', prosthesisOption as Array<ProductOption>)}
              onBlur={handleBlur}
              error={touched.prosthesis && Boolean(errors.prosthesis?.value)}
              helperText={touched.prosthesis?.value && errors.prosthesis?.value}
            >
              {prosthesisOption?.map((item: ProductOption) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.value}
                </MenuItem>
              ))}
            </TextField>

            <Button
              variant="outlined"
              onClick={() => {
                handleOpenDialogue();
                setSelectedEditOption('PROSTHESIS');
              }}
            >
              Edit
            </Button>
          </ListItem>

          <ListItem sx={{ gap: 2, alignItems: 'baseline' }}>
            <Typography>Method</Typography>
            <TextField
              style={{ height: '4rem' }}
              select
              fullWidth
              id="method"
              name="method"
              variant="outlined"
              label={'Method'}
              value={values?.method.optionId}
              onChange={(e) => handleFieldChange(e, 'method', methodOption as Array<ProductOption>)}
              onBlur={handleBlur}
              disabled={false}
              error={touched.method && Boolean(errors.method)}
              helperText={touched.method?.value && errors.method?.value}
            >
              {methodOption?.map((item: ProductOption) => (
                <MenuItem key={item.id} value={item?.id}>
                  {item.value}
                </MenuItem>
              ))}
            </TextField>
            <Button
              variant="outlined"
              onClick={() => {
                handleOpenDialogue();
                setSelectedEditOption('METHOD');
              }}
            >
              Edit
            </Button>
          </ListItem>
          <ListItem sx={{ gap: 2, alignItems: 'baseline' }}>
            <Typography>*Material</Typography>
            <TextField
              style={{ height: '4rem' }}
              select
              fullWidth
              id="material"
              name="material"
              variant="outlined"
              label={'Material'}
              value={values?.material.optionId}
              onChange={(e) => handleFieldChange(e, 'material', materialOption as Array<ProductOption>)}
              onBlur={handleBlur}
              disabled={false}
              error={touched.material && Boolean(errors.material)}
              helperText={touched.material?.value && errors.material?.value}
            >
              {materialOption?.map((item: ProductOption) => (
                <MenuItem key={item.id} value={item?.id}>
                  {item.value}
                </MenuItem>
              ))}
            </TextField>
            <Button
              variant="outlined"
              onClick={() => {
                handleOpenDialogue();
                setSelectedEditOption('MATERIAL');
              }}
            >
              Edit
            </Button>
          </ListItem>
          <ListItem sx={{ gap: 2, alignItems: 'baseline' }}>
            <Typography>Shape</Typography>
            <TextField
              style={{ height: '4rem' }}
              select
              fullWidth
              id="shape"
              name="shape"
              label={'Shape'}
              variant="outlined"
              value={values?.shape.optionId}
              onChange={(e) => handleFieldChange(e, 'shape', shapeOption as Array<ProductOption>)}
              onBlur={handleBlur}
              disabled={false}
              error={touched.shape?.value && Boolean(errors.shape?.value)}
              helperText={touched.shape?.value && errors.shape?.value}
            >
              {shapeOption?.map((item: ProductOption) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.value}
                </MenuItem>
              ))}
            </TextField>
            <Button
              variant="outlined"
              onClick={() => {
                handleOpenDialogue();
                setSelectedEditOption('SHAPE');
              }}
            >
              Edit
            </Button>
          </ListItem>
          <ListItem sx={{ gap: 2, alignItems: 'baseline' }}>
            <Typography>*Order Range</Typography>
            <TextField
              style={{ height: '4rem', width: '14.5rem' }}
              select
              fullWidth
              id="designManufactureCombination"
              name="designManufactureCombination"
              variant="outlined"
              value={values?.designManufactureCombination}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.designManufactureCombination && Boolean(errors.designManufactureCombination)}
              helperText={touched.designManufactureCombination && errors.designManufactureCombination}
            >
              {SELECT_DESIGN_MANUFACTURE_COMBINATION_OPTION?.map((item: SelectDto) => {
                return (
                  <MenuItem key={item.id} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </ListItem>
          <ListItem sx={{ gap: 2, alignItems: 'baseline' }}>
            <Typography>*Unique Price</Typography>
            <TextField
              style={{ height: '4rem', width: '10rem' }}
              type="number"
              fullWidth
              id="price"
              name="price"
              variant="outlined"
              value={values?.price}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={false}
              error={touched.price && Boolean(errors.price)}
              helperText={touched.price && errors.price}
            />
          </ListItem>

          <ListItem sx={{ alignItems: 'baseline' }}>
            <Stack direction="row" alignItems="center">
              <Checkbox
                id={'requireScanBodyAndImplantInfo'}
                name={'requireScanBodyAndImplantInfo'}
                value={values.requireScanBodyAndImplantInfo}
                onChange={handleChange}
              />
              <Typography>Request for Scanbody and Implant Details</Typography>
            </Stack>
          </ListItem>
        </List>
      </Box>

      <EditOptionDialog
        open={open}
        onCloseDialogue={handleCloseDialogue}
        selectedEditOption={selectedEditOption}
        userSelectedEditOptionList={getUserSelectedEditOptionList() as Array<ProductOption>}
      />

      {/*<CustomDialog title="Add" open={true} handleClose={() => {}} btnAction={() => {}} btnActionText="Add">*/}
      {/*  <Box py={2}>*/}
      {/*    <TextField*/}
      {/*      style={{ height: '4rem' }}*/}
      {/*      type="text"*/}
      {/*      fullWidth*/}
      {/*      id="orgName"*/}
      {/*      name="add"*/}
      {/*      label={`Adddd`}*/}
      {/*      variant="outlined"*/}
      {/*      value={'entity'}*/}
      {/*      onChange={(e) => {*/}
      {/*        if (e?.target?.value?.length <= 128) {*/}
      {/*          console.log(e.target.value);*/}
      {/*        }*/}
      {/*      }}*/}
      {/*      // error={fieldError.error}*/}
      {/*      // helperText={fieldError.error ? fieldError.message : ''}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*</CustomDialog>*/}
    </>
  );
};
